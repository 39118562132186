import React, { Component, createRef } from 'react';
import OverlayHeaderForPopups from '../../../../Components/OverlayHeaderForPopups/OverlayHeaderForPopups';
import classes from './CreateOrEdit.module.css';
import { Modal } from 'reactstrap';
import '../../../BusinessUnitSelection/BusinessUnitSelection.module.css';

import MultipleChoice from './FormElements/MultipleChoice';
import YesNo from './FormElements/YesNo';
import TextResponse from './FormElements/TextResponse';
import Rating from './FormElements/Rating';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	createSurveyTemplateNameAction,
	createSurveyQuestionAction,
	editSurveyQuestionAction,
	deleteSurveyQuestionAction
} from '../../../../Store/actions/surveysAction';

import {
	createEvaluationTemplateNameAction,
	createEvaluationQuestionAction,
	editEvaluationQuestionAction,
	deleteEvaluationQuestionAction
} from '../../../../Store/actions/evaluationsAction';
import SavePopUp from '../SavePopUp/SavePopUp';

const availableQuestions = {
	multiple_choice: {
		requiredResponse: false,
		questionAnswers: [],
		question: '',
		allowMultipleResponses: false,
		id: 0,
		order: 0,
		type: 'multiple'
	},
	yes_no: {
		question: '',
		requiredResponse: false,
		allowNAResponse: false,
		questionAnswers: [ 'YES', 'NO' ],
		id: 0,
		order: 0,
		type: 'yesno'
	},
	text_response: {
		question: '',
		requiredResponse: false,
		id: 0,
		order: 0,
		type: 'text'
	},
	rating: {
		question: '',
		questionAnswers: [],
		requiredResponse: false,
		allowMultipleResponses: false,
		selectedScale: 5,
		minimumAndMaximumScaleLabels: [ '', '' ],
		id: 0,
		order: 0,
		type: 'rating'
	}
};

class Edit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			templateName: props.templateName || '',
			questions:
				(props.questions &&
					props.questions.map((q, i) => {
						return {
							allowMultipleResponses: q.allow_multiple === '1',
							id: q.id,
							order: i !== 0 && q.id === 0 ? i + 1 : q.ordering,
							questionAnswers: q.question_options,
							minimumAndMaximumScaleLabels: [ q.min_rating_label, q.max_rating_label ],
							question: q.questions,
							requiredResponse: q.is_required === '1',
							allowNAResponse: q.question_options.find((option) => option.options === 'N/A'),
							selectedScale: q.question_options.length,
							type: q.questions_type
						};
					}))
					.sort((q1, q2) => {
						return q1.order > q2.order;
					}) || [],

			savePopUpOpen: false,
			savingQuestions: false
		};
	}

	AddAQuestionContainerRef = createRef();

	handleFormSubmit = (ev, saveInto) => {
		ev.preventDefault();
		const { trialID, id } = this.props.match.params;

		const parameters = {
			trialID,
			templateName: this.state.templateName,
			saveInto: saveInto,
			templateID:  saveInto != 'trial' && id,
			questions: this.state.questions
		};
		this.setState({ savingQuestions: true });
		this.props.type === 'survey'
			? this.props.saveSurveyTemplateName(parameters, this.saveQuestions)
			: this.props.saveEvaluationTemplateName(parameters, this.saveQuestions);
	};

	saveQuestions = () => {
		this.state.questions.length > 0 && this.saveQuestion(0);
	};

	saveQuestion = (questionIndex) => {
		const { id, trialID } = this.props.match.params;
		const question = questionIndex < this.state.questions.length ? this.state.questions[questionIndex] : false;
		if (question) {
			if (question.type === 'multiple') {
				let formatMultipleChoiceQuestionData = {
					...question,
					questionAnswers: question.questionAnswers,
					questionType: 'multiple',
					trialID,
					templateID: id,
					order: questionIndex + 1,
					requiredResponse: question.requiredResponse === true ? '1' : '0',
					allowMultipleResponses: question.allowMultipleResponses === true ? '1' : '0'
				};

				if (question.justAdded) {
					this.props.type === 'survey'
						? this.props.createSurveyQuestion(formatMultipleChoiceQuestionData, () => this.saveQuestion(questionIndex + 1))
						: this.props.createEvaluationQuestion(formatMultipleChoiceQuestionData, () => this.saveQuestion(questionIndex + 1));
				} else {
					this.props.type === 'survey'
						? this.props.saveSurveyQuestion(formatMultipleChoiceQuestionData, () => this.saveQuestion(questionIndex + 1))
						: this.props.saveEvaluationQuestion(formatMultipleChoiceQuestionData, () => this.saveQuestion(questionIndex + 1));
				}
			} else if (question.type === 'yesno') {
				let formatYesNoQuestionData = {
					...question,
					question: question.question,
					questionAnswers: question.questionAnswers,
					trialID,
					order: questionIndex + 1,
					questionType: 'yesno',
					templateID: id,
					requiredResponse: question.requiredResponse === true ? '1' : '0',
					allowNAResponse: question.allowNAResponse === true ? '1' : '0'
				};

				if (question.justAdded) {
					this.props.type === 'survey'
						? this.props.createSurveyQuestion(formatYesNoQuestionData, () => this.saveQuestion(questionIndex + 1))
						: this.props.createEvaluationQuestion(formatYesNoQuestionData, () => this.saveQuestion(questionIndex + 1));
				} else {
					this.props.type === 'survey'
						? this.props.saveSurveyQuestion(formatYesNoQuestionData, () => this.saveQuestion(questionIndex + 1))
						: this.props.saveEvaluationQuestion(formatYesNoQuestionData, () => this.saveQuestion(questionIndex + 1));
				}
			} else if (question.type === 'text') {
				let formatTextReponseQuestionData = {
					...question,
					question: question.question,
					trialID,
					order: questionIndex + 1,
					questionType: 'text',
					templateID: id,
					requiredResponse: question.requiredResponse === true ? '1' : '0'
				};

				if (question.justAdded) {
					this.props.type === 'survey'
						? this.props.createSurveyQuestion(formatTextReponseQuestionData, () => this.saveQuestion(questionIndex + 1))
						: this.props.createEvaluationQuestion(formatTextReponseQuestionData, () => this.saveQuestion(questionIndex + 1));
				} else {
					this.props.type === 'survey'
						? this.props.saveSurveyQuestion(formatTextReponseQuestionData, () => this.saveQuestion(questionIndex + 1))
						: this.props.saveEvaluationQuestion(formatTextReponseQuestionData, () => this.saveQuestion(questionIndex + 1));
				}
			} else if (question.type === 'rating') {
				let formatRatingQuestionData = {
					...question,
					question: question.question,
					trialID,
					questionAnswers: question.questionAnswers.map((qa) => {
						if (qa.option_id === '' && qa.is_display === '0') qa.option_id = '0';
						return qa;
					}),
					order: questionIndex + 1,
					questionType: 'rating',
					templateID: id,
					requiredResponse: question.requiredResponse === true ? '1' : '0',
					allowMultipleResponses: question.allowMultipleResponses === true ? '1' : '0',
					minRatingLabel: question.minimumAndMaximumScaleLabels[0],
					maxRatingLabel: question.minimumAndMaximumScaleLabels[1],
					ratingScale: question.selectedScale
				};
				if (question.justAdded) {
					this.props.type === 'survey'
						? this.props.createSurveyQuestion(formatRatingQuestionData, () => this.saveQuestion(questionIndex + 1))
						: this.props.createEvaluationQuestion(formatRatingQuestionData, () => this.saveQuestion(questionIndex + 1));
				} else {
					this.props.type === 'survey'
						? this.props.saveSurveyQuestion(formatRatingQuestionData, () => this.saveQuestion(questionIndex + 1))
						: this.props.saveEvaluationQuestion(formatRatingQuestionData, 		() => this.saveQuestion(questionIndex + 1));
				}
			}
		} else {
			this.setState({
				savingQuestions: false
			});
			this.props.toggleSurveyCreateOrEditQuestionairePopup();
		}
	};

	handleInputChange = (ev) => {
		this.setState({
			[ev.currentTarget.id]: ev.currentTarget.value
		});
	};

	handleQuestionChange = (changedQuestion) => {
		let cloneQuestions = this.state.questions.map((item) => {
			let clonedItem = item;
			if (item.id === changedQuestion.id) {
				clonedItem = { ...item, ...changedQuestion };
			}
			return clonedItem;
		});

		this.setState({
			questions: cloneQuestions
		});
	};

	openAddQuestionContainer = () => {
		this.AddAQuestionContainerRef.current.classList.toggle(classes['hidden']);
	};

	addQuestion = (keyword) => {
		const currentQuestions = this.state.questions;
		availableQuestions[keyword] &&
			currentQuestions.push({
				...availableQuestions[keyword],
				id: currentQuestions.length,
				justAdded: true
			});
		this.setState({ questions: currentQuestions });
		this.openAddQuestionContainer();
	};

	removeQuestion = (questionId, questionType) => {
		const { trialID, type, id } = this.props.match.params;
		const parameters = {
			trialID: trialID,
			templateID: id,
			questionID: questionId,
			questionType: questionType
		};

		type === 'survey'
			? this.props.deleteSurveyQuestion(parameters)
			: this.props.deleteEvaluationQuestion(parameters);
		const cloneQuestions = this.state.questions.filter((q) => q.id !== questionId);
		this.setState({ questions: cloneQuestions });
	};

	moveQuestionDown = (questionId) => {
		const clonedQuestions = this.state.questions;
		const orderedQuestions = [ clonedQuestions.length ];
		const limit = clonedQuestions.length - 1;
		for (let i = 0; i < clonedQuestions.length; i++) {
			const currentQuestion = clonedQuestions[i];
			if (currentQuestion.id === questionId && i !== limit) {
				const nextQuestion = clonedQuestions[i + 1];
				nextQuestion.order = i;
				orderedQuestions[i] = nextQuestion;
				currentQuestion.order = i + 1;
				orderedQuestions[i + 1] = currentQuestion;
				i++;
			} else {
				orderedQuestions[i] = currentQuestion;
			}
		}

		this.setState({ questions: orderedQuestions });

		/* const currentPlace = clonedQuestions.findIndex(q => q.id === questionId);
		const splicedQuestions = clonedQuestions.splice(clonedQuestions.findIndex(q => q.id === questionId), 1); */
	};

	openSaveMenu = () => {
		this.setState({ savePopUpOpen: !this.state.savePopUpOpen });
	};

	render() {
		const {
			surveyCreateOrEditQuestionairePopupIsOpen,
			toggleSurveyCreateOrEditQuestionairePopup,
			type
		} = this.props;

		return (
			<Modal
				id="form-survey"
				className={classes['form-edit-and-create-survey-container']}
				isOpen={surveyCreateOrEditQuestionairePopupIsOpen}
				toggle={toggleSurveyCreateOrEditQuestionairePopup}
				backdrop="static"
			>
				<OverlayHeaderForPopups
					title={`Edit ${type}`}
					togglePopup={toggleSurveyCreateOrEditQuestionairePopup}
					buttonSubmitCallback={this.openSaveMenu}
					editing={this.state.templateName != ''}
				/>

				<form className={classes['form-edit-and-create-survey']}>
					<div className={classes['form-survey__card']}>
						<div className={classes['form-survey__card-title-container']}>
							<h3 className={classes['form-survey__card-title']}>Edit {type} details</h3>
						</div>

						<div className={classes['form-survey__card-group']}>
							<label className={classes['form-survey__card-label']} htmlFor="templateName">
								{type} name
							</label>
							<input
								value={this.state.templateName}
								onChange={this.handleInputChange}
								className={classes['form-survey__card-input']}
								type="text"
								id="templateName"
								placeholder="template name"
							/>
						</div>
					</div>
					{this.state.questions.map((item, index) => {
						if (item.type === 'multiple') {
							return (
								<MultipleChoice
									key={index}
									id={item.id}
									order={index + 1}
									item={item}
									handleQuestionChange={this.handleQuestionChange}
									moveQuestionDown={this.moveQuestionDown}
									onDelete={() => this.removeQuestion(item.id, item.type)}
								/>
							);
						} else if (item.type === 'yesno') {
							return (
								<YesNo
									key={index}
									id={item.id}
									item={item}
									order={index + 1}
									handleQuestionChange={this.handleQuestionChange}
									moveQuestionDown={this.moveQuestionDown}
									onDelete={() => this.removeQuestion(item.id, item.type)}
								/>
							);
						} else if (item.type === 'text') {
							return (
								<TextResponse
									key={index}
									id={item.id}
									item={item}
									order={index + 1}
									handleQuestionChange={this.handleQuestionChange}
									moveQuestionDown={this.moveQuestionDown}
									onDelete={() => this.removeQuestion(item.id, item.type)}
								/>
							);
						} else if (item.type === 'rating') {
							return (
								<Rating
									key={index}
									id={item.id}
									item={item}
									order={index + 1}
									handleQuestionChange={this.handleQuestionChange}
									moveQuestionDown={this.moveQuestionDown}
									onDelete={() => this.removeQuestion(item.id, item.type)}
								/>
							);
						}
					})}

					{/* <DragAndDrop
                        handleDragAndDropQuestionChange={ this.handleDragAndDropQuestionChange } /> */}
				</form>

				<div className={classes['form-survey__add-question-wrapper']}>
					<div className="right-min">
						<div
							ref={this.AddAQuestionContainerRef}
							className={[ classes['form-survey__buttons-container'], classes['hidden'] ].join(' ')}
						>
							<button
								data-question-type="rating"
								className={classes['form-survey__button']}
								onClick={() => this.addQuestion('rating')}
							>
								Rating
							</button>
							<hr />
							<button
								data-question-type="text-response"
								className={classes['form-survey__button']}
								onClick={() => this.addQuestion('text_response')}
							>
								Text Response
							</button>
							<hr />
							<button
								data-question-type="multiple"
								className={classes['form-survey__button']}
								onClick={() => this.addQuestion('multiple_choice')}
							>
								Multiple Choice
							</button>
							<hr />
							<button
								data-question-type="yes-no"
								className={classes['form-survey__button']}
								onClick={() => this.addQuestion('yes_no')}
							>
								Yes/No
							</button>
						</div>
						<i
							onClick={this.openAddQuestionContainer}
							className={[ classes['form-survey__add-icon'], 'las la-plus' ].join(' ')}
						/>
					</div>
				</div>
				{this.state.savePopUpOpen && (
					<SavePopUp
						saveTemplate={this.handleFormSubmit}
						surveyAndEvaluationSaveFormPopUpIsOpen={this.state.savePopUpOpen}
						saving={this.state.savingQuestions}
						toggleSurveyAndEvaluationSaveFormPopUp={this.openSaveMenu}
					/>
				)}
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		createSurveyAndEvaluationTemplateID:
			state.surveysAndEvaluations.createSurveysAndEvaluationsTemplateName.template_id
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		saveSurveyTemplateName: (parameters, callback) => {
			dispatch(createSurveyTemplateNameAction(parameters, callback));
		},
		saveEvaluationTemplateName: (parameters, callback) => {
			dispatch(createEvaluationTemplateNameAction(parameters, callback));
		},
		createSurveyQuestion: (parameters, saveNextQuestion) => {
			dispatch(createSurveyQuestionAction(parameters, saveNextQuestion));
		},
		createEvaluationQuestion: (parameters, saveNextQuestion) => {
			dispatch(createEvaluationQuestionAction(parameters, saveNextQuestion));
		},
		saveSurveyQuestion: (parameters, saveNextQuestion) => {
			dispatch(editSurveyQuestionAction(parameters, saveNextQuestion));
		},
		saveEvaluationQuestion: (parameters, saveNextQuestion) => {
			dispatch(editEvaluationQuestionAction(parameters, saveNextQuestion));
		},
		deleteSurveyQuestion: (parameters) => {
			dispatch(deleteSurveyQuestionAction(parameters));
		},
		deleteEvaluationQuestion: (parameters) => {
			dispatch(deleteEvaluationQuestionAction(parameters));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Edit));
